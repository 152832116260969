class PasswordValidator {
    static hasLowerCase(password: string): boolean {
        return /[a-z]/.test(password);
    }

    static hasUpperCase(password: string): boolean {
        return /[A-Z]/.test(password);
    }

    static hasNumber(password: string): boolean {
        return /\d/.test(password);
    }

    static hasSpecialCharacter(password: string): boolean {
        return /[!@#$%^&*]/.test(password);
    }

    static isLengthValid(password: string): boolean {
        return password.length >= 8;
    }
}

export default PasswordValidator;